var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ESLBaseElement, attr } from '@exadel/esl/modules/esl-base-element/core';
import { ready } from '@exadel/esl/modules/esl-utils/decorators/ready';
import { isIE } from '@exadel/esl/modules/esl-utils/environment/device-detector';
import { bind } from '@exadel/esl/modules/esl-utils/decorators/bind';
import { memoize } from '@exadel/esl/modules/esl-utils/decorators/memoize';
import { range } from '@exadel/esl/modules/esl-utils/misc/array';
export class EAKBanner extends ESLBaseElement {
    constructor() {
        super(...arguments);
        this._animateTimer = 0;
        this._$active = [];
    }
    connectedCallback() {
        super.connectedCallback();
        if (isIE)
            return;
        this.startAnimation();
    }
    disconnectedCallback() {
        this.stopAnimation();
        super.disconnectedCallback();
    }
    get $lines() {
        return Array.from(this.querySelectorAll(EAKBanner.TARGETS));
    }
    get $randomLine() {
        const index = Math.floor(Math.random() * this.$lines.length);
        return this.$lines[index];
    }
    startAnimation() {
        this._onIteration();
    }
    stopAnimation() {
        this._animateTimer && window.clearTimeout(this._animateTimer);
    }
    _onIteration() {
        const $candidates = range(this.targetsNumber, () => this.$randomLine);
        const delay = this.iterationTime / this.targetsNumber;
        if (!$candidates.length)
            return;
        this._$active.forEach((line, i) => {
            window.setTimeout(() => {
                line.classList.remove('animate');
                line.style.strokeDashoffset = '';
            }, delay * i);
        });
        $candidates.forEach((line, i) => {
            window.setTimeout(() => {
                line.classList.add('animate');
                const lineLength = line.getTotalLength();
                line.style.strokeDasharray = lineLength + '';
                line.style.strokeDashoffset = lineLength + '';
            }, delay * i);
        });
        this._$active = $candidates;
        this._animateTimer = window.setTimeout(this._onIteration, this.iterationTime);
    }
}
EAKBanner.is = 'eak-banner';
EAKBanner.TARGETS = 'g > path';
__decorate([
    attr({ defaultValue: 2, parser: parseInt })
], EAKBanner.prototype, "targetsNumber", void 0);
__decorate([
    attr({ defaultValue: 4000, parser: parseInt })
], EAKBanner.prototype, "iterationTime", void 0);
__decorate([
    ready
], EAKBanner.prototype, "connectedCallback", null);
__decorate([
    memoize()
], EAKBanner.prototype, "$lines", null);
__decorate([
    bind
], EAKBanner.prototype, "_onIteration", null);
