var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { attr } from '@exadel/esl/modules/esl-utils/decorators/attr';
import { prop } from '@exadel/esl/modules/esl-utils/decorators/prop';
import { listen } from '@exadel/esl/modules/esl-utils/decorators/listen';
import { ESLMediaQuery } from '@exadel/esl/modules/esl-media-query/core';
import { ESLToggleable } from '@exadel/esl/modules/esl-toggleable/core';
export class EAKSidebar extends ESLToggleable {
    constructor() {
        super(...arguments);
        this.closeOnEsc = true;
        this.closeOnOutsideAction = true;
        this.submenus = '.sidebar-nav-secondary.nav-secondary-panel';
        this.activeMenuAttr = 'data-open';
    }
    get $submenus() {
        return Array.from(this.querySelectorAll(this.submenus));
    }
    setInitialState() {
        const isDesktop = ESLMediaQuery.for('@+MD').matches;
        this.toggle(isDesktop, { force: true, initiator: 'init', immediate: true });
    }
    collapseAll() {
        this.$submenus.forEach((menu) => menu.hide({ activator: this }));
    }
    expandActive(noCollapse = false) {
        this.$submenus
            .filter((menu) => menu.hasAttribute('data-open'))
            .forEach((menu) => menu.show({ noCollapse, activator: this }));
    }
    updateA11y() {
        const targetEl = this.$a11yTarget;
        if (!targetEl)
            return;
        targetEl.setAttribute('aria-expanded', String(this.open));
    }
    hide(params) {
        if (ESLMediaQuery.for('@+MD').matches)
            return this;
        return super.hide(params);
    }
    onShow(params) {
        this._animation = !params.immediate;
        super.onShow(params);
        this.expandActive(params.initiator === 'init');
    }
    onHide(params) {
        this._animation = !params.immediate;
        super.onHide(params);
        this.collapseAll();
    }
    onBreakpointChange({ matches: isDesktop }) {
        this.toggle(isDesktop, { force: true, initiator: 'bp-change', immediate: !isDesktop });
    }
}
EAKSidebar.is = 'eak-sidebar';
__decorate([
    prop()
], EAKSidebar.prototype, "closeOnEsc", void 0);
__decorate([
    prop()
], EAKSidebar.prototype, "closeOnOutsideAction", void 0);
__decorate([
    prop()
], EAKSidebar.prototype, "submenus", void 0);
__decorate([
    prop()
], EAKSidebar.prototype, "activeMenuAttr", void 0);
__decorate([
    attr({ name: 'animation' })
], EAKSidebar.prototype, "_animation", void 0);
__decorate([
    listen({
        event: 'change',
        target: ESLMediaQuery.for('@+MD')
    })
], EAKSidebar.prototype, "onBreakpointChange", null);
