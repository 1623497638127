var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ESLBaseElement } from '@exadel/esl/modules/esl-base-element/core';
import { listen } from '@exadel/esl/modules/esl-utils/decorators/listen';
import { attr } from '@exadel/esl/modules/esl-utils/decorators/attr';
import { boolAttr } from '@exadel/esl/modules/esl-utils/decorators/bool-attr';
import { memoize } from '@exadel/esl/modules/esl-utils/decorators/memoize';
import { getTouchPoint } from '@exadel/esl/modules/esl-utils/dom/events/misc';
export class EAKZoomImage extends ESLBaseElement {
    get $image() {
        const originalImage = document.createElement('esl-image');
        originalImage.setAttribute('data-src', this.src);
        originalImage.setAttribute('lazy', '');
        originalImage.setAttribute('mode', 'fit');
        return originalImage;
    }
    get $closeButton() {
        const button = document.createElement('button');
        button.setAttribute('class', 'close-button');
        return button;
    }
    connectedCallback() {
        super.connectedCallback();
        this.appendChild(this.$image);
        this.appendChild(this.$closeButton);
    }
    zoom() {
        if (this.inDrag || this.inZoom)
            return;
        this.inZoom = true;
        this.$image.style.transform = `scale(${this.scale})`;
        this.$image.style.marginTop = '0';
        this.$image.style.marginLeft = '0';
        this.style.width = `${this.$image.offsetWidth}px`;
        this.style.height = `${this.$image.offsetHeight}px`;
        this.$$on('pointerdown', this.onPointerDown);
    }
    unzoom() {
        this.inZoom = false;
        this.$image.style.removeProperty('transform');
        this.$image.style.marginTop = '0';
        this.$image.style.marginLeft = '0';
        this.style.width = 'auto';
        this.style.height = 'auto';
        this.$$off(this.onPointerDown);
    }
    onPointerDown(e) {
        this.inDrag = true;
        this.startPoint = getTouchPoint(e);
        this.startPosition = {
            x: parseInt(this.$image.style.marginLeft, 10) || 0,
            y: parseInt(this.$image.style.marginTop, 10) || 0,
        };
        this.$$on('pointermove', this.onPointerMove);
        this.$$on('pointerup', this.onPointerUp);
        this.setPointerCapture(e.pointerId);
        e.preventDefault();
    }
    onPointerUp(e) {
        if (!this.inDrag)
            return;
        e.preventDefault();
        this.inDrag = false;
        this.$$off(this.onPointerMove);
        this.$$off(this.onPointerUp);
        this.releasePointerCapture(e.pointerId);
    }
    onPointerMove(e) {
        if (!this.inDrag)
            return;
        const point = getTouchPoint(e);
        const scale = parseInt(this.scale, 10);
        const offsetX = point.x - this.startPoint.x;
        const offsetY = point.y - this.startPoint.y;
        const maxOffsetX = (this.$image.offsetWidth * scale - this.offsetWidth) * 0.5;
        const maxOffsetY = (this.$image.offsetHeight * scale - this.offsetHeight) * 0.5;
        if (maxOffsetX < 0 || maxOffsetY < 0)
            return;
        const x = this.startPosition.x + offsetX;
        const y = this.startPosition.y + offsetY;
        const normalizedX = Math.min(maxOffsetX, Math.max(-maxOffsetX, x));
        const normalizedY = Math.min(maxOffsetY, Math.max(-maxOffsetY, y));
        this.$image.style.marginTop = `${normalizedY}px`;
        this.$image.style.marginLeft = `${normalizedX}px`;
        e.preventDefault();
    }
    onClick(e) {
        const isCloseBtn = this.$closeButton.contains(e.target);
        isCloseBtn ? this.unzoom() : this.zoom();
    }
    onOutsideClick(e) {
        if (!this.$image.contains(e.target)) {
            this.unzoom();
        }
    }
}
EAKZoomImage.is = 'eak-zoom-image';
__decorate([
    attr({ dataAttr: true })
], EAKZoomImage.prototype, "src", void 0);
__decorate([
    attr({ defaultValue: '2' })
], EAKZoomImage.prototype, "scale", void 0);
__decorate([
    boolAttr()
], EAKZoomImage.prototype, "inZoom", void 0);
__decorate([
    boolAttr()
], EAKZoomImage.prototype, "inDrag", void 0);
__decorate([
    memoize()
], EAKZoomImage.prototype, "$image", null);
__decorate([
    memoize()
], EAKZoomImage.prototype, "$closeButton", null);
__decorate([
    listen('pointerdown')
], EAKZoomImage.prototype, "onClick", null);
__decorate([
    listen({ event: 'pointerdown', target: 'body' })
], EAKZoomImage.prototype, "onOutsideClick", null);
